'use client';

import React, { useRef, useState } from 'react';
import { useParams } from 'next/navigation';
import Link from 'next/link';
import Image from 'next/image';
import { Box, BoxProps, Separator, Flex, FlexProps, HStack, Stack, useDisclosure, Group } from '@chakra-ui/react';
import { FiMenu, FiUsers } from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import { BiBriefcase, BiCog, BiQr } from 'react-icons/bi';
import { MdDashboard } from 'react-icons/md';

import { FaChevronDown } from 'react-icons/fa';
import { PiBuildingOffice } from 'react-icons/pi';
import { OwnerWithRoles } from '@shared/zod-schemas';
import { usePathname, useRouter } from '@/i18n/routing';
import { useIsAdminOrSuperAdmin } from '@/hooks/useIsAdminOrSuperAdmin';
import NavButton from '@/components/nav/NavButton';
import { Button } from '@/components/ui/button';

import { ColorModeButton, useColorMode } from '@/components/ui/color-mode';
import { CloseButton } from '@/components/ui/close-button';
import { DrawerContent, DrawerRoot } from '@/components/ui/drawer';
import {
	PopoverArrow,
	PopoverBody,
	PopoverCloseTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverRoot,
	PopoverTrigger,
} from '@/components/ui/popover';
import { RadioCardItem, RadioCardRoot } from '@/components/ui/radio-card';
import { NotificationPopover } from '../notification/NotificationPopover';
import NavUserPopover from './NavUserPopover';

import LogoTaskgripLight from '../../images/logoTaskgripLight.svg';
import LogoTaskgrip from '../../images/logoTaskgrip.svg';
import Command from '../command/Command';
import Breadcrumb from './Breadcrumb';
import Submenu from './Submenu';
import { SubsTrialBox } from '../subscription/SubsBox';

interface MobileProps extends FlexProps {
	onOpen: () => void;
	owners: OwnerWithRoles[];
	onClose: () => void;
}

interface SidebarProps extends BoxProps {
	onClose: () => void;
	owners: OwnerWithRoles[];
}

function SidebarContent({ onClose, owners, ...rest }: SidebarProps) {
	const n = useTranslations('Nav');
	const path = usePathname();
	const params = useParams();
	const router = useRouter();

	const isDarkMode = useColorMode().colorMode === 'dark';

	const [open, setOpen] = useState(false);

	const isAdmin = useIsAdminOrSuperAdmin();

	const EmployeeSubmenu = [
		{ text: n('employeesOverview'), path: '/overview' },
		{ text: n('employeesAttendance'), path: '' },
		{ text: n('employeesManagement'), path: '/employee-management' },
		{ text: n('employeesMap'), path: '/employee-map' },
	];

	const ref = useRef<HTMLButtonElement>(null);

	const actualOwner = owners.find((owner) => owner.id === params.ownerId);

	const isTrialDue = actualOwner?.trialEndDate && new Date(actualOwner.trialEndDate) < new Date();
	const hasTrialDateInFuture = actualOwner?.trialEndDate && new Date(actualOwner.trialEndDate) > new Date();
	const hasTrial = !actualOwner?.stripeSubscriptionId && hasTrialDateInFuture && isTrialDue;

	return (
		<Box
			transition="3s ease"
			bg="bg.card"
			boxShadow="md"
			w={{ base: 'full', md: 60 }}
			pos="fixed"
			h="full"
			pt={{ base: '4', md: 0 }}
			mt={{ base: 0, md: 16 }}
			{...rest}
		>
			<Flex
				display={{ base: 'flex', md: 'none' }}
				pr={7}
				justifyContent="space-between"
				alignItems="center"
				width="auto"
				ml={6}
			>
				<Link href="/app" onClick={onClose}>
					{isDarkMode ? (
						<Image src={LogoTaskgripLight} alt="taskgrip" height={33} />
					) : (
						<Image src={LogoTaskgrip} alt="taskgrip" height={33} />
					)}
				</Link>
				<CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
			</Flex>
			{owners.length !== 0 && (
				<Flex alignItems="center" my={5} mx={6}>
					<PopoverRoot initialFocusEl={() => ref.current} open={open} onOpenChange={(e) => setOpen(e.open)}>
						<PopoverTrigger asChild>
							<Button size="sm" variant="outline" width="full">
								<PiBuildingOffice />
								{actualOwner?.companyName} <FaChevronDown />
							</Button>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverHeader>Dostupné firmy</PopoverHeader>
							<PopoverArrow />
							<PopoverBody>
								<RadioCardRoot defaultValue={actualOwner?.id} gap="4" maxW="sm">
									<Group attached orientation="vertical">
										{owners.map((owner) => (
											<RadioCardItem
												width="full"
												indicatorPlacement="start"
												label={owner?.companyName}
												description={owner?.userRole}
												key={owner.id}
												value={owner?.id || ''}
												onClick={() => {
													router.push(`/app/owners/${owner.id}`);
													onClose();
													setOpen(false);
												}}
											/>
										))}
									</Group>
								</RadioCardRoot>
							</PopoverBody>
							<PopoverCloseTrigger />
						</PopoverContent>
					</PopoverRoot>
				</Flex>
			)}
			{params.ownerId && (
				<>
					<Separator />
					<Stack gap={2} pt={5} px={4}>
						<NavButton
							text={n('dashboard')}
							icon={<MdDashboard size="20px" />}
							onClick={() => {
								onClose();
							}}
							href={`/app/owners/${params.ownerId}`}
							isActive={path === `/app/owners/${params.ownerId}`}
						/>

						{isAdmin && (
							<Submenu ownerId={params.ownerId as string} onClose={onClose} items={EmployeeSubmenu} />
						)}

						<NavButton
							text={n('jobs')}
							icon={<BiBriefcase size="20px" />}
							onClick={() => {
								onClose();
							}}
							href={`/app/owners/${params.ownerId}/jobs`}
							isActive={path === `/app/owners/${params.ownerId}/jobs`}
						/>

						{isAdmin && (
							<>
								{params.ownerId && (
									<NavButton
										text={n('customers')}
										icon={<FiUsers size="20px" />}
										href={`/app/owners/${params.ownerId}/customers`}
										isActive={path === `/app/owners/${params.ownerId}/customers`}
									/>
								)}
								<NavButton
									text={n('settings')}
									icon={<BiCog size="20px" />}
									onClick={() => {
										onClose();
									}}
									href={`/app/owners/${params.ownerId}/settings`}
									isActive={path === `/app/owners/${params.ownerId}/settings`}
								/>

								<NavButton
									text={n('qr')}
									icon={<BiQr size="20px" />}
									onClick={() => {
										onClose();
									}}
									href={`/app/owners/${params.ownerId}/qr`}
									isActive={path === `/app/owners/${params.ownerId}/qr`}
								/>
							</>
						)}
					</Stack>
				</>
			)}
			<Box pl={7} mt={6} mb={2} display={{ base: 'block', md: 'none' }}>
				<Breadcrumb onClose={onClose} />
			</Box>

			{!!actualOwner?.stripeSubscriptionId && (
				<Box mt="auto" px={6} py={8}>
					<SubsTrialBox hasTrial={hasTrial} ownerId={actualOwner?.id} />
				</Box>
			)}
		</Box>
	);
}

function MobileNav({ onOpen, onClose, ...rest }: MobileProps) {
	const params = useParams();

	const isDarkMode = useColorMode().colorMode === 'dark';

	return (
		<Flex
			ml={{ base: 0, md: params.ownerId ? 0 : 0 }}
			pl={{ base: 4, md: 6 }}
			pr={{ base: 4, md: 4 }}
			height="16"
			alignItems="center"
			bg="bg.card"
			boxShadow={{ base: 'none', md: 'md' }}
			justifyContent="space-between"
			pos="fixed"
			width={{ base: 'full', md: '100%' }}
			zIndex={3}
			{...rest}
		>
			<Button display={{ base: 'flex', md: 'none' }} onClick={onOpen} variant="outline" aria-label="open menu">
				<FiMenu />
			</Button>

			<Flex alignItems="center" display={{ base: 'none', md: 'flex' }} gap={{ base: '64px', xl: '136px' }}>
				<Link href="/app" onClick={onClose}>
					{isDarkMode ? (
						<Image src={LogoTaskgripLight} alt="taskgrip" height={33} />
					) : (
						<Image src={LogoTaskgrip} alt="taskgrip" height={33} />
					)}
				</Link>

				<Breadcrumb />
			</Flex>

			<HStack gap={4} height="64px" alignItems="center">
				<Command />
				<NotificationPopover />
				<ColorModeButton />
				<NavUserPopover />
			</HStack>
		</Flex>
	);
}

function SidebarWithHeader({ children, owners }: { children: React.ReactNode; owners: OwnerWithRoles[] }) {
	const { open, onOpen, onClose } = useDisclosure();
	const params = useParams();

	return (
		<Box minH="100vh" bg="bg.baseMain">
			{params.ownerId && (
				<SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} owners={owners} />
			)}
			<DrawerRoot open={open} onExitComplete={onClose} size="full">
				<DrawerContent>
					<SidebarContent onClose={onClose} owners={owners} />
				</DrawerContent>
			</DrawerRoot>

			{params.ownerId && <MobileNav onOpen={onOpen} owners={owners} onClose={onClose} />}

			<Box
				ml={{ base: 0, md: params.ownerId ? 60 : 0 }}
				pt="118px"
				pl={{ base: 5, md: 14 }}
				pr={{ base: 5, md: 14 }}
				pb={{ base: 5, md: 14 }}
			>
				{children}
			</Box>
		</Box>
	);
}

export default SidebarWithHeader;
