import { useParams } from 'next/navigation';
import { useGetUserRolesByOwnerId } from '@shared/client-api';
import { useUser } from '@/supabase/AuthContext';
import { ROLES } from '@/constants/roles';

export const useIsAdminOrSuperAdmin = () => {
	const params = useParams();

	const user = useUser();

	const userRoles = useGetUserRolesByOwnerId(
		{
			q: {
				where: {
					userId: user?.id,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					ownerId: params?.ownerId,
				},
			},
		},
		{
			query: {
				enabled: Boolean(params?.ownerId),
				staleTime: 1000 * 60 * 60,
			},
		},
	);

	return userRoles?.data?.[0]?.role === ROLES.ADMIN || userRoles?.data?.[0]?.role === ROLES.SUPER_ADMIN;
};
